import { motion } from "framer-motion"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import React, { useLayoutEffect, useRef } from "react"
import styled from "styled-components"
import "./LocationCards.css"
import img1 from "../assets/Images/varthur.webp"
import img2 from "../assets/Images/mahadeva.avif"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const reviews = [
  {
    name: "Sonal Mahskey",
    avatar: "S",
    text: "A perfect destination to learn almost any kind of dance moves. John Sir and Nishant Sir are brilliant dancers and instructor. Believe me if anyone's interested in dancing, atleast join one free demo class and you'll be insisted by yourself to join the class. They also have zumba that is also nice. Kids adults all are eligible for the classes.",
    date: "a year ago",
  },
  {
    name: "Deepmala Deb",
    avatar: "D",
    text: "Thrilled with my son's experience at Tapping Feet Dance Studio! The instructors are fantastic, creating a welcoming environment for kids to learn and have fun.  Nishant sir and John sir are brilliant dancer and instructor. I've seen my son confidence grow as he learns new dance moves. The studio's positive atmosphere is truly commendable. Excited to see his progress in the coming sessions!",
    date: "23 days ago",
  },
  {
    name: "Vyshu Navi",
    avatar: "V",
    text: "It's awesome place to learn dance. I joined my kid here for the dance class. He is learning well now. Instructors were very well managing with all the kids and concentrating individually as well. Special thanks to nishant and john master.. They are excellent dancers..Thanks to nirmala and devi mam too",
    date: "23 days ago",
  },
  {
    name: "Clinton Bob",
    avatar: "L",
    text: "Amazing and wonderful guidance always. PerfeCt way to go for zumba and fitness for all. Very much personal guidance on every move an amazing to join along.Must join for sure!! Thank you Nishant sir!! It’s definitely the best fitness centre to join inn",
    date: "20 days ago",
  },
  {
    name: "Karan Patel",
    avatar: "K",
    text: "This was so much fun. Im not a skilled dancer at all but had a great time! I loved learning Zumba and dances in Tapping Feet. The instructors (NASH & JOHN) was lovely and I would highly recommend it to everyone if youre in Bangalore and if you want to lose weight and brush up on dancing skills!!!!",
    date: "30 days ago",
  },
  {
    name: "Abhishek Zackrias",
    avatar: "A",
    text: "Best place to start your dance fitness and best trained choreographers a gud platform for kids. More extra cericular activities like music and reality show's chances etc. please open more branches so that everyone gets gud future.",
    date: "1 month ago",
  },

  {
    name: "Deepa Singh",
    avatar: "D",
    text: "Great experience",
    date: "2 months ago",
  },
  {
    name: "Neha Gupta",
    avatar: "N",
    text: "Just 1/2 Months Ago And So Far I'm Seeing Great Results😊Zumba Has Become My Favorite Workout...! It Is Fun And Energetic",
    date: "2 months ago",
  },
  {
    name: "Rakesh Kumar",
    avatar: "R",
    text: "Nash is one of the best Zumba Instructor ever...a stunning dancer... Fantastic energy.",
    date: "2 months ago",
  },

  // You can add more reviews as needed up to 20
]

const Section = styled.section`
  min-height: 100vh;
  /* height: auto; */
  width: 100%;
  margin: 0 auto;
  /* height: 300vh; */

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  /* background-color: ${(props) => props.theme.text}; */
`

const Overlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30vw;
  height: 90vh;
  box-shadow: 0 0 0 5vw ${(props) => props.theme.text};
  border: 3px solid black;

  z-index: 11;

  @media (max-width: 70em) {
    width: 40vw;

    height: 80vh;
  }

  @media (max-width: 64em) {
    width: 50vw;
    box-shadow: 0 0 0 60vw ${(props) => props.theme.text};

    height: 80vh;
  }
  @media (max-width: 48em) {
    width: 60vw;

    height: 80vh;
  }
  @media (max-width: 30em) {
    width: 80vw;

    height: 60vh;
  }
`

const Container = styled.div`
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 25vw;
  height: auto;
  /* background-color: yellow; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 64em) {
    width: 30vw;
  }
  @media (max-width: 48em) {
    width: 40vw;
  }
  @media (max-width: 30em) {
    width: 60vw;
  }
`

const Title = styled(motion.h1)`
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: "Kaushan Script";
  font-weight: 300;
  /* text-transform: capitalize; */
  color: ${(props) => props.theme.body};
  text-shadow: 1px 1px 1px ${(props) => props.theme.text};

  position: absolute;
  top: 2rem;
  left: 1rem;
  z-index: 15;

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxxl};
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`
const Text = styled.div`
  width: 20%;
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 300;
  position: absolute;
  padding: 2rem;
  top: 0;
  right: 0;
  z-index: 11;

  @media (max-width: 48em) {
    display: none;
  }
`
const NewArrival = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Number of slides to show at once
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  gsap.registerPlugin(ScrollTrigger)
  const ref = useRef(null)

  const ScrollingRef = useRef(null)

  useLayoutEffect(() => {
    let element = ref.current

    let scrollingElement = ScrollingRef.current
    let t1 = gsap.timeline()
    setTimeout(() => {
      let mainHeight = scrollingElement.scrollHeight
      element.style.height = `calc(${mainHeight / 4}px)`
      t1.to(element, {
        scrollTrigger: {
          trigger: element,
          start: "top top",
          end: "bottom+=100% top-=100%",
          scroller: ".App", //locomotive-scroll
          scrub: 1,
          pin: true,
          // markers: true,
        },
        ease: "none",
      })

      t1.fromTo(
        scrollingElement,
        {
          y: "0",
        },
        {
          y: "-100%",
          scrollTrigger: {
            // id: `section-${index + 1}`,
            trigger: scrollingElement,
            start: "top top",
            end: "bottom top",
            scroller: ".App",
            scrub: 1,
            // markers: true,
          },
        }
      )

      ScrollTrigger.refresh()
    }, 1000)
    ScrollTrigger.refresh()

    return () => {
      t1.kill()
      ScrollTrigger.kill()
    }
  }, [])
  return (
    <>
      <Section ref={ref} id="fixed-target" className="new-arrival">
        <Title
          data-scroll
          data-scroll-speed="-2"
          data-scroll-direction="horizontal"
        >
          Find Our Locations...
        </Title>

        <div className="card-container">
          <div className="location-card">
            <img src={img1} alt="Varthur Location" />
            <h3>Varthur Location</h3>
            <p>
              <strong>Address:</strong> 36/3 Sai Gandhi Square, Varthur Main Rd,
              opposite Sigma Tech Park, Ramagondanahalli, Whitefield, Bengaluru,
              Karnataka 560066
            </p>
            <p>
              <strong>Contact:</strong> +91 81973 96646
            </p>
            <a
              href="https://maps.app.goo.gl/GXBWB5Nk5DxYbx8R6"
              target="_blank"
              rel="noopener noreferrer"
              className="map-button"
            >
              <FontAwesomeIcon icon={faMapMarkerAlt} /> View on Map
            </a>
            &nbsp;&nbsp;
            <a
              href="https://wa.me/918197396646"
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-button"
            >
              <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp
            </a>
          </div>

          <div className="location-card">
            <img src={img2} alt="Mahadevapura Location" />
            <h3>Mahadevapura Location</h3>
            <p>
              <strong>Address:</strong> chummys challete, 32/4, MTB JJV School
              road, near MTB School, Garudachar Palya, Mahadevapura, Bengaluru,
              Karnataka 560048
            </p>
            <p>
              <strong>Contact:</strong> +91 97424 28890
            </p>
            <a
              href="https://maps.app.goo.gl/kYQScnynaUK4Nitw9"
              target="_blank"
              rel="noopener noreferrer"
              className="map-button"
            >
              <FontAwesomeIcon icon={faMapMarkerAlt} /> View on Map
            </a>
            &nbsp;&nbsp;
            <a
              href="https://wa.me/919742428890"
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-button"
            >
              <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp
            </a>
          </div>
        </div>
      </Section>
      <div style={styles.sliderContainer}>
        <h2 style={styles.heading}>Customer Reviews</h2>
        <Slider {...settings}>
          {reviews.map((review, index) => (
            <div key={index} style={styles.card}>
              <div style={styles.avatar}>{review.avatar}</div>
              <h4 style={styles.name}>{review.name}</h4>
              <p style={styles.date}>{review.date}</p>
              <p style={styles.text}>{review.text}</p>
            </div>
          ))}
        </Slider>
      </div>
      <br />
      <br />
    </>
  )
}
const styles = {
  sliderContainer: {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "40px 20px",
    textAlign: "center",
  },
  heading: {
    fontSize: "2rem",
    marginBottom: "30px",
    color: "#333",
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    textAlign: "center",
  },
  avatar: {
    backgroundColor: "#3498db",
    color: "#fff",
    fontSize: "24px",
    fontWeight: "bold",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto 15px",
  },
  name: {
    fontSize: "1.2rem",
    color: "#2c3e50",
    marginBottom: "10px",
  },
  date: {
    fontSize: "0.9rem",
    color: "#7f8c8d",
    marginBottom: "15px",
  },
  text: {
    fontSize: "1rem",
    color: "#34495e",
  },
}
export default NewArrival
